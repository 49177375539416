<template>
  <div :class="containerClassList" :name="name">
    <label :for="id" :class="derivedLabelClass">{{ label }}</label>
    <input
      :id="id"
      :class="inputClassList"
      :type="inputType.DATE"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
      :autocomplete="autocomplete"
      :name="name"
      v-model="innerValue"
      @input="handleInput($event.target.value)"
      @blur="handleBlur($event.target.value)"
    />
    <img
      src="@/assets/images/icons/general/Calendar.svg"
      :class="dateFieldIcon"
    />
    <Balloon :text="message" :arrowPosition="balloonArrowPosition" />
  </div>
</template>

<script>
import {
  InputType,
  BalloonArrowPosition,
  INPUT_CONTAINER_CLASS,
  DATE_FIELD,
} from '@/constants'
import { inputMixin } from '@/mixins'

const CSN_DATE_FIELD = 'csn-date-field'
const CSN_DATE_FIELD_INPUT = `${CSN_DATE_FIELD}-input`
const CSN_DATE_FIELD_ICON = `${CSN_DATE_FIELD}-icon`
const INPUT_CLASS = 'form-control'
const AUTOCOMPLETE_ON = 'on'

export default {
  name: DATE_FIELD,
  inheritAttrs: false,
  components: {
    Balloon: () => import('@/components/Balloon'),
  },
  mixins: [inputMixin],
  props: {
    readonly: Boolean,
    type: {
      type: String,
      default: InputType.TEXT,
    },
    autocomplete: {
      type: String,
      default: AUTOCOMPLETE_ON,
    },
  },
  computed: {
    containerClassList() {
      return [INPUT_CONTAINER_CLASS, CSN_DATE_FIELD, this.containerClass]
    },
    inputClassList() {
      return [INPUT_CLASS, CSN_DATE_FIELD_INPUT, this.inputClass]
    },
    balloonArrowPosition: () => BalloonArrowPosition.TOP_LEFT,
    dateFieldIcon: () => CSN_DATE_FIELD_ICON,
  },
}
</script>
